:root {
  --themeColor: ;
  --themeColorSecondary: ;
}
.userProfile__heading {
  margin-top: 2.3rem;
  font-size: 1.5rem;
  margin-left: 0.2rem;
  font-weight: 600;
  color: #fc6b6c;
  letter-spacing: 0.5px;
  font-family: "Source Sans Pro", "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.userProfile__topHeaders {
  margin-top: 1.3rem;
  display: flex;
  align-items: center;
}
.userProfile__topHeaders > p {
  background-color: #fff;
  color: #444;
  padding: 0 0.8rem;
  font-size: 0.9rem;
  letter-spacing: 0.5px;
  font-family: "Source Sans Pro", "Helvetica Neue", Helvetica, Arial, sans-serif;
  padding-bottom: 5px;
  cursor: pointer;
}
.userProfile__topActive {
  border-bottom: 2px solid var(--themeColorSecondary);
  border-right: 1px solid #f4f4f4;
  border-left: 1px solid #f4f4f4;
}
.userProfile__mainContent {
  margin-left: 0.7rem;
}
.userProfile__mainContentInputLabel {
  font-size: 0.9rem;
  line-height: 1.428571429;
  font-weight: 700;
  font-family: "Source Sans Pro", "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.userProfile__mainContentInputData {
  width: 32%;
  height: 18px;
  margin-top: -12px;
  padding: 16px 12px;
  font-size: 14px;
  line-height: 1.428571429;
  color: #555;
  vertical-align: middle;
  background-color: #fff;
  border: 1px solid #ccc;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}
.userProfile__mainContentInputData:is(input):focus-visible {
  outline: none;
  border: 1px solid #3c8dbc;
}
.userProfile__mainContentButton {
  margin-top: 1rem;
  width: 32%;
  padding: 0.5rem 0;
  color: white;
  background-color: #fc6b6c;
  border: none;
}
.alert-success {
  background: none !important;
  color: green !important;
}

.alert {
  padding: 6px;
  margin-bottom: 10px;
}

hr {
  margin: 1rem 0;
  color: inherit;
  background-color: rgb(155, 153, 153) !important;
  border: 0;
  opacity: 0.25;
}

.profile-margin {
  border-bottom: 1px solid #f4f4f4;
  margin-bottom: 1rem;
}

.profile-error {
  background: none !important;
  color: red;
  padding: 6px;
  margin-bottom: 10px;
  border-radius: 3px;
  border: 1px solid #d73925;
}
