.loginPage {
  height: 100vh;
  background-color: white;
  padding: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  /* border: 4px solid red; */
  /* background-image: url("../../assets/BG/previewBG.png"); */
  /* margin: -15px; */
  /* padding: 15px; */
}
.leftDivLogin{
  width: 65%;
  /* border: 2px solid black; */
  height: 100vh;
  display: flex;
  gap: 60px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: -80px;
}
.loginOptionLogin{
  font-size: 22px;
  font-family: 'Poppins', sans-serif;
  font-weight: 300;
}
.loginTagLogin{
  font-size: 22px;
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  color: #004aad;
}
.rightDiv1Login{
  width: 35%;
  /* border: 2px solid black; */
  /* background-color: #19acb4; */
  background-image: url("../../assets/BG/bg.png");
  background-size: 100%;
  height: 100vh;
  display: flex;
  gap: 60px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.errorDivLogin{
  margin-top: -60px;
}
.errorDivLogin p{
  font-size: 16px;
  color:red;
  font-family: 'Poppins', sans-serif;
  font-weight: 300;
  height: 20px;
  min-height: 20px;
}
.errorDivLogin h6{
  color: white;
  margin: 12px 0;
}
.craeteHeaderLogin{
  font-size: 48px;
  font-weight: 600;
  color: #19ACB4;
  font-family: 'Poppins', sans-serif;
}
.inputDivLogin{
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin-top: -40px;
}
.InputBoxLogin{
  /* background-color: red; */
  display: flex;
  align-items: center;
  /* border: 2px solid red; */
  outline: none;
  border-radius: 40px;
  background-color: #d9d9d9;
  padding: 7px;
  width: 330px;
  padding-left: 20px;
  font-size: larger;
  color: black;
  font-family: 'Poppins', sans-serif;
  /* border: 2px solid rebeccapurple; */
}
.InputBox2Login{
  /* background-color: red; */
  border: none;
  outline: none;
  width: 100%;
  border-radius: 40px;
  background-color: #d9d9d9;
  /* padding: 7px; */
  /* width: 380px; */
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-left: 20px;
  font-size: larger;
  color: black;
  color-scheme: black;
  
  /* border: 2px solid rebeccapurple; */
}
.InputBox2Login::placeholder{
  color: black;
  font-size: 400;
  font-weight: 300;
}
.InputBox2Login:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px #d9d9d9 inset;
}
.CirlceLogin{
  border: 1px solid black;
  display: flex;
  justify-content: center;
  padding: 2px;
  border-radius: 50%;
}
.forgotDivLogin{
  /* border: 1px solid red; */
  width: 320px;
  display: flex;
  font-family: 'Poppins', sans-serif;
  color: #004aad;
  font-weight: 300;
  font-size: medium;
  margin: -35px 0;
  justify-content: flex-end;
}
.forgotAtagLogin{
  width: 320px;
  display: flex;
  font-family: 'Poppins', sans-serif;
  color: #004aad;
  font-weight: 300;
  font-size: medium;
  text-decoration: none;
  /* margin: -35px 0; */
  cursor: pointer;
  justify-content: flex-end;

}

.welcomeHeadingLogin{
  color: white;
  font-weight: 600;
  font-size: 42px;
  width: 100%;
  /* border: 1px solid red; */
  justify-content: center;
  align-items: center;
  font-family: 'Poppins', sans-serif;
  /* font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif ; */
  

  /* border: 1px solid red; */
  /* width: 400px; */
  font-stretch: ultra-expanded;
}
.dialogeHeadingLogin{
  /* max-width: 50px; */
  width: 280px;
  text-align: center;
  color: white;
  font-size: 22px;
  font-weight: 300;
  margin-top: 20px;
  font-family: 'Poppins', sans-serif;
  /* border: 1px solid red; */
}
.loginButton{
  color: white;
  font-size: 23.5px;
  font-weight: 300;
  text-align: center;
  padding: 5px;
  width: 170px;
  border-radius: 40px;
  background-color: #40c1c8;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  /* border: 3px solid white; */
  font-family: 'Poppins', sans-serif;
  /* opacity: 0.6; */
  /* transition: 0.3s; */
  box-shadow: 0 3px #999;
}
.signUpButtonLogin:hover{
  animation: ease-in;
  background-color: #19ACB4
}
.signUpButtonLogin:active {
  background-color: #19ACB4;
  box-shadow: 0 3px #666;
  transform: translateY(4px);
}
.signinButtonLogin{
  color: white;
  font-size: 23.5px;
  font-weight: 300;
  text-align: center;
  padding: 5px;
  width: 170px;
  border-radius: 40px;
  cursor: pointer;
  border: 3px solid white;
  font-family: 'Poppins', sans-serif;
  /* opacity: 0.6; */
  transition: 0.3s;
  box-shadow: 0 3px #40c1c8;
}
.signinButtonLogin:active {
  background-color: #19ACB4;
  box-shadow: 0 5px #40c1c8;
  transform: translateY(4px);
}
.signinButtonLogin:hover{
  /* background-color: #19acb45e; */
  animation: ease-in;
  background-color: #1d777cb6;
}

.centerDivLogin{
  display: flex;
  flex-direction: column;
  gap: 50px;
  align-items: center;
  margin-top: -40px;
  /* border: 1px solid red; */
}
.aTagLogin{
  color: white;
  text-decoration: none;
}
.aTagLogin:hover{
  color: white;
}

.login {
  /* height: 100vh;
  background-color: #54636f;
  padding: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center; */
  /* background-image: url("https://wallpaperaccess.com/full/2314983.jpg"); */
  /* background-image: url("../../assets/BG/previewBG.png"); */
}
.pre-head {
  font-weight: 700;
}
.login__form {
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
}
.form-input {
  /* border: none;
  margin-bottom: 25px;
  outline: 0 !important;
  background: transparent !important;
  border-bottom: 1px solid #abb2b8 !important;
  padding: 10px 20px !important;
  font-size: 24px !important;
  box-shadow: none !important;
  color: #abb2b8 !important;
  border-radius: 5px !important; */
}
.login__button {
  /* border: none;
  background: transparent;
  color: #abb2b8;
  font-size: 24px !important;
  padding: 10px 16px;
  cursor: pointer;
  line-height: 1.33;
  border-radius: 6px;
  border: 2px solid transparent; */
}
.login__button:focus {
  background: #428bca;
  color: white;
  border: 2px solid black;
}
.login__button:hover {
  color: white;
  background: transparent;
}
.alert {
  padding: 15px;
  border: 1px solid transparent;
  border-radius: 4px;
}
.alert-danger {
  /* background: f2dede !important;
  color: red !important;
  border: 1px solid; */
}
.form-signin-heading {
  color: #fff;
  text-align: center;
}
.login__headingHead {
  color: #fff;
  font-weight: 550;
}

.heading{
  /* margin-bottom: 2px; */
  position: relative;
  bottom: 60px;
  color: #fff;
}

@media (max-width: 965px){
  .welcomeHeadingLogin {
    font-size: 32px;
  }
}

@media (max-width: 768px) {
  .leftDivLogin {
    width: 100%;
    margin-top: 0;
  }
  
  .rightDiv1Login {
    width: 60%;
  }
  
  /* .loginButton,
  .signinButtonLogin {
    width: 320px;
  } */
  
  .welcomeHeadingLogin {
    font-size: 30px;
  }
  
  .dialogeHeadingLogin {
    width: 250px;
  }
  
  .InputBoxLogin,
  .InputBox2Login {
    width: 280px;
  }
}

@media (max-width: 600px) {
  .loginPage {
    padding: 0;
  }

  .rightDiv1Login{
    display: none;
  }
  .leftDivLogin {
    width: 100%;
  }
  
  .loginTagLogin,
  .loginOptionLogin {
    font-size: 18px;
  }
  
  .craeteHeaderLogin {
    font-size: 36px;
  }
  
  .InputBoxLogin,
  .InputBox2Login {
    width: 250px;
  }
  
  .loginButton,
  .signinButtonLogin {
    width: 250px;
    font-size: 20px;
  }
  
  .welcomeHeadingLogin {
    font-size: 30px;
  }
  
  .dialogeHeadingLogin {
    width: 200px;
  }
}