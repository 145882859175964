.leave-report {
  .box-header {
    padding: 31px 0 0 0 !important;
    margin-bottom: 20px;
    .page-title {
      font-size: 24px;
      font-weight: 600;
      color: #fc6b6c;
      letter-spacing: 0.5px;
    }
  }

  .apply-leave .table-content {
    margin-bottom: 3rem;
    overflow: auto !important;
  }

  .middle-content {
    .table-content {
      margin-bottom: 3rem;
      overflow: auto;
      .table > tbody > tr:hover > td,
      .table > tbody > tr:hover > th {
        background-color: #f5f5f5;
      }
      .total-time {
        p {
          font-size: 18px;
        }
        .bold-time {
          font-weight: bold;
        }
      }

      table {
        border-color: #f4f4f4;

        .no-record-found {
          background: #e9e9e9;
          height: 100px;
          box-shadow: inset 0 2px 6px 0 #d6d6d6;
          font-size: 14px;
          font-weight: 600;
          color: #000;
          td {
            vertical-align: middle;
            font-size: 16px;
          }
        }

        th {
          font-size: 18px;
          font-weight: 600;
          color: #54636f;
        }

        tr {
          &:first-child {
            border-top: 1px solid #f4f4f4;
          }
        }
      }
    }
  }
}
