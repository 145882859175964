.css-jegsr1-MuiTableContainer-root {
  width: 100%;
  overflow-x: auto;
  padding-top: 12px;
}
.css-1bnwcgu {
  width: 100%;
  overflow-x: auto;
  padding-top: 12px;
  padding-bottom: 70px !important;
}

.css-1f4th7r-MuiTableCell-root {
  border-bottom: 1px solid #f4f4f4 !important;
}

.css-1g7hcih-MuiTableCell-root {
  border-bottom: 1px solid #f4f4f4 !important;
}

.css-1g7hcih-MuiTableCell-root {
  border-bottom: 1px solid #f4f4f4 !important;
}

.css-7471p4-MuiTable-root .MuiTableCell-root {
  border-bottom: 1px solid #f4f4f4 !important;
}

.projects {
  .box-header {
    padding: 31px 0 0 0;
    margin-bottom: 20px;
    .page-title {
      font-size: 24px;
      font-weight: 600;
      color: #fc6b6c;
      letter-spacing: 0.5px;
    }
  }
}
