.users {
  .box-header {
    padding: 31px 0 0 0;
    margin-bottom: 20px;
    .page-title {
      font-size: 24px;
      font-weight: 600;
      color: #fc6b6c;
      letter-spacing: 0.5px;
    }
  }
  .invite-email {
    display: flex;
    align-items: center;
    width: 30%;
    input {
      border: 0;
      border-bottom: 1px solid #bebebe;
      border-radius: 0;
      &:focus {
        box-shadow: unset;
        border-bottom: 1px solid #bebebe;
      }
    }
    button {
      font-size: 20px;
      font-weight: 600;
      color: #fc6b6c;
      letter-spacing: 0.5px;
      &:focus {
        box-shadow: unset;
      }
    }
  }

  .table-content {
    margin-bottom: 3rem;
    overflow: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none;
    }

    .table > tbody > tr:hover > td,
    .table > tbody > tr:hover > th {
      background-color: #f5f5f5;
    }

    /* Hide scrollbar for Chrome, Safari and Opera */

    table {
      border-color: #f4f4f4;
      th {
        font-size: 18px;
        font-weight: 600;
        color: #54636f;
        padding-bottom: 1rem !important;
      }

      tr {
        &:first-child {
          border-top: 1px solid #f4f4f4;
        }
      }
    }
  }

  .no-record-found {
    background: #e9e9e9;
    height: 100px;
    box-shadow: inset 0 2px 6px 0 #d6d6d6;
    font-size: 14px;
    font-weight: 600;
    color: #000;
    td {
      vertical-align: middle;
      font-size: 16px;
    }
  }
}
