.header {
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
  .header-content {
    padding: 1rem;
    input {
      border: 0;
      color: white;
      font-size: 18px;
      &::placeholder {
        color: white;
      }
      &:focus {
        box-shadow: unset;
      }
    }
    select {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      background-image: unset;
      background-color: #4dccc3;
      padding: 0.2rem 1rem;
      border: none;
      color: white;
      font-size: 16px;
      &:focus {
        box-shadow: unset;
      }
    }
    select option {
      background: white;
      color: #54636f;
      text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
    }
    .adjustWidth {
      @media screen and (max-width: 991px) and (min-width: 767px) {
        width: 80%;
      }
      .mui-select-custom {
        width: 80%;
        .MuiOutlinedInput-notchedOutline {
          border-color: rgb(0 0 0 / 0%);
        }
      }
      .MuiSelect-select.MuiSelect-outlined {
        width: 100%;
        padding: 0;
        color: white;
        font-family: "Source Sans Pro", "Helvetica Neue", Helvetica, Arial,
          sans-serif;
      }
    }
    label.wrap {
      width: 80%;
      display: flex;
      align-items: center;
    }
  }
  .timer {
    .timer-tag {
      color: white;
      font-size: 25px;
    }
    button {
      box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);

      border-radius: 50px;
      padding: 1.3rem;
      position: absolute;
      right: 35px;
      top: 3%;
      &:focus {
        box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
      }
      img {
        width: 45px;
      }
      @media screen and (max-width: 991px) {
        right: 15px;
      }
      @media screen and (max-width: 767px) {
        top: 9.5%;
      }
      @media screen and (max-width: 414px) and (min-width: 390px) {
        top: 8%;
      }
    }
  }
  .timer-play {
    position: relative;
    left: 5px;
  }
}

.MuiMenu-list {
  display: block;
  // min-width: 300px;
  padding: 6px 10px !important;
  .MuiMenuItem-root {
    box-sizing: border-box;
    white-space: nowrap;
    color: #54636f;
    font-family: "Source Sans Pro" !important;
    border-bottom: 1px solid #f5f5f5 !important;
    &:last-child {
      border-bottom: 0px !important;
    }
  }
  .Mui-selected {
    background-color: rgba(182, 176, 176, 0.12) !important;
  }
}
