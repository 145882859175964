

.signupPage {
  height: 100vh;
  background-color: white;
  padding: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-image: url("../../assets/BG/previewBG.png"); */
  margin: -15px;
  /* border: 3px solid red; */
}

.leftDivSignup{
  width: 65%;
  /* border: 2px solid black; */
  height: 100vh;
  display: flex;
  gap: 60px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* margin-top: -40px; */
}

.loginOptionSignup{
  font-size: 22px;
  font-family: 'Poppins', sans-serif;
  font-weight: 300;
}
.loginTagSignup{
  font-size: 22px;
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  color: #004aad;
}
.errorDiv{
  /* border: 1px solid red; */
  margin-top: -60px;
}
.errorDiv p{
  font-size: 16px;
  color:red;
  font-family: 'Poppins', sans-serif;
  font-weight: 300;
  height: 20px;
  min-height: 20px;
}
.errorDiv h6{
  color: white;
  margin: 12px 0;
}

.rightDivSignup{
  width: 35%;
  /* border: 2px solid black; */
  /* background-color: #19acb4; */
  background-image: url("../../assets/BG/bg.png");
  background-size: 100%;
  height: 100vh;
  display: flex;
  gap: 60px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.craeteHeaderSignup{
  font-size: 48px;
  font-weight: 600;
  color: #19ACB4;
  font-family: 'Poppins', sans-serif;
}
.inputDivSignup{
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin-top: -40px;
}
.InputBoxSignup{
  /* background-color: red; */
  display: flex;
  align-items: center;
  /* border: 2px solid red; */
  outline: none;
  border-radius: 40px;
  background-color: #d9d9d9;
  padding: 7px;
  width: 330px;
  padding-left: 20px;
  font-size: larger;
  color: black;
  font-family: 'Poppins', sans-serif;
  /* border: 2px solid rebeccapurple; */
}
.InputBoxSignup2{
  /* background-color: red; */
  border: none;
  outline: none;
  border-radius: 40px;
  background-color: #d9d9d9;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  /* background: red; */

  /* padding: 7px; */
  /* width: 380px; */
  padding-left: 20px;
  /* padding-right: -10px; */
  margin-right: 10px;
  font-size: larger;
  color: black;
  color-scheme: black;
  
  /* border: 2px solid rebeccapurple; */
}
.InputBoxSignup2::placeholder{
  color: black;
  font-size: 400;
  font-weight: 300;
}
.InputBoxSignup2:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px #d9d9d9 inset;
}
.CirlceSignup{
  border: 1px solid black;
  display: flex;
  justify-content: center;
  padding: 2px;
  border-radius: 50%;
}

.welcomeHeadingSignup{
  color: white;
  font-weight: 600;
  font-size: 42px;
  font-family: 'Poppins', sans-serif;
  /* font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif ; */
  

  /* border: 1px solid red; */
  /* width: 400px; */
  font-stretch: ultra-expanded;
}
.dialogeHeadingSignup{
  /* max-width: 50px; */
  width: 280px;
  text-align: center;
  color: white;
  font-size: 22px;
  font-weight: 300;
  margin-top: 20px;
  font-family: 'Poppins', sans-serif;
  /* border: 1px solid red; */
}
.signUpButtonSignup{
  color: white;
  font-size: 23.5px;
  font-weight: 300;
  text-align: center;
  padding: 5px;
  display: grid;
  align-items: center;
  justify-content: center;
  width: 170px;
  border-radius: 40px;
  background-color: #40c1c8;
  cursor: pointer;
  /* border: 3px solid white; */
  font-family: 'Poppins', sans-serif;
  /* opacity: 0.6; */
  /* transition: 0.3s; */
  box-shadow: 0 3px #999;
}
.signUpButtonSignup:hover{
  animation: ease-in;
  background-color: #19ACB4
}
.signUpButtonSignup:active {
  background-color: #19ACB4;
  box-shadow: 0 3px #666;
  transform: translateY(4px);
}
.signinButtonSignup{
  color: white;
  font-size: 23.5px;
  font-weight: 300;
  text-align: center;
  padding: 5px;
  width: 170px;
  border-radius: 40px;
  cursor: pointer;
  border: 3px solid white;
  font-family: 'Poppins', sans-serif;
  /* opacity: 0.6; */
  transition: 0.3s;
  box-shadow: 0 3px #40c1c8;
}
.signinButtonSignup:active {
  background-color: #19ACB4;
  box-shadow: 0 5px #40c1c8;
  transform: translateY(4px);
}
.signinButtonSignup:hover{
  /* background-color: #19acb45e; */
  animation: ease-in;
  background-color: #1d777cb6;
}

.centerDivSignup{
  display: flex;
  flex-direction: column;
  gap: 50px;
  align-items: center;
  margin-top: -40px;
  /* border: 1px solid red; */
}
.aTagSignup{
  color: white;
  text-decoration: none;
}
.aTagSignup:hover{
  color: white;
}
/* *{
    background-color: aqua;
    border: 2px solid red;
} */
.signup {
    height: 100vh;
    background-color: white;
    padding: 0 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background-image: url("../../assets/BG/previewBG.png"); */
    margin: -15px;
  }
  .pre-head {
    font-weight: 700;
  }
  .login__form {
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
    /* border: 2px solid red; */
  }
  .form-input {
    border: none;
    margin-bottom: 25px;
    outline: 0 !important;
    background: transparent !important;
    border-bottom: 1px solid #abb2b8 !important;
    padding: 10px 20px !important;
    font-size: 24px !important;
    box-shadow: none !important;
    color: #abb2b8 !important;
    border-radius: 5px !important;
  }
  .login__button {
    border: none;
    background: transparent;
    color: #abb2b8;
    font-size: 24px !important;
    padding: 10px 16px;
    cursor: pointer;
    line-height: 1.33;
    border-radius: 6px;
    border: 2px solid transparent;
  }
  .login__button:focus {
    background: #428bca;
    color: white;
    border: 2px solid black;
  }
  .login__button:hover {
    color: white;
    background: transparent;
  }
  .alert {
    padding: 15px;
    border: 1px solid transparent;
    border-radius: 4px;
  }
  .alert-danger {
    background: f2dede !important;
    color: red !important;
    border: 1px solid;
  }
  .form-signin-heading {
    color: #fff;
    text-align: center;
  }
  .login__headingHead {
    color: #fff;
    font-weight: 550;
  }
  .already-user{
    display: flex;
    /* align-items: center; */
    justify-content: center;
    color: #abb2b8;
    margin-top: 10px;
  }
  .a-tag{
    text-decoration: none;
    color: white;
  }
  .a-tag:hover{
    color: #abb2b8;
  }

  .heading{
    /* margin-bottom: 2px; */
    position: relative;
    bottom: 60px;
    color: #fff;
  }
  
  @media (max-width: 965px){
    .welcomeHeadingSignup {
      font-size: 32px;
    }
  }
  
  @media (max-width: 768px) {
    .leftDivSignup {
      width: 100%;
      margin-top: 0;
    }
    
    .rightDivSignup {
      width: 60%;
    }
    
    /* .loginButton,
    .signinButtonLogin {
      width: 320px;
    } */
    
    .welcomeHeadingSignup {
      font-size: 30px;
    }
    
    .dialogeHeadingSignup {
      width: 250px;
    }
    
    .InputBoxSignup,
    .InputBox2Signup2 {
      width: 280px;
    }
  }
  
  @media (max-width: 600px) {
    .signupPage {
      padding: 0;
    }
  
    .rightDivSignup{
      display: none;
    }
    .leftDivSignup {
      width: 100%;
    }
    
    .loginTagSignup,
    .loginOptionSignup {
      font-size: 18px;
    }
    
    .craeteHeaderSignup {
      font-size: 36px;
    }
    
    /* .InputBoxSignup,
    .InputBox2Signup2 {
      width: 250px;
    } */
    
    .signUpButtonSignup,
    .signinButtonSignup {
      width: 250px;
      font-size: 20px;
    }
    
    .welcomeHeadingSignup {
      font-size: 30px;
    }
    
    .dialogeHeadingSignup {
      width: 200px;
    }
  }