.UpdateProject-type-content {
  .box-header {
    padding: 31px 0 0 0;
    margin-bottom: 20px;
    .add-client {
      font-size: 20px;
      font-weight: 600;
      color: #fc6b6c;
      letter-spacing: 0.5px;
      cursor: pointer;
      white-space: nowrap;
    }
  }
}
.Update-Project {
  h2#customized-dialog-title {
    color: #fc6b6c;
    font-size: 16px;
    text-align: center;
    padding-top: 0;
    svg {
      border: 1px solid #9e9e9e;
      border-radius: 50px;
    }
  }
  .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
    padding: 1rem;
  }
  .css-1t4vnk2-MuiDialogContent-root {
    border-bottom: 0;
  }
  .form-width {
    min-width: 500px;
    label {
      display: inline-block;
      margin-bottom: 5px;
      font-weight: bold;
    }

    select {
      border: 0;
      border-bottom: 1px solid #bebebe;
      border-radius: 0;
      &:focus {
        box-shadow: unset;
      }
    }

    .flex-input {
      position: relative;
      display: flex;
      .date-icon {
        position: absolute;
        right: 13px;
        top: 7px;
      }

      // date picker css
      .react-datepicker-wrapper {
        position: absolute;
        opacity: 0;
        z-index: 3;
      }
      .react-datepicker-popper[data-placement^="top"]
        .react-datepicker__triangle::after {
        bottom: 1px;
      }

      .react-datepicker-popper[data-placement^="top"]
        .react-datepicker__triangle::before,
      .react-datepicker-popper[data-placement^="bottom"]
        .react-datepicker__triangle::before,
      .react-datepicker-popper[data-placement^="top"]
        .react-datepicker__triangle::after,
      .react-datepicker-popper[data-placement^="bottom"]
        .react-datepicker__triangle::after {
        left: -17px;
      }
      .react-datepicker__triangle {
        transform: translate(30px, 0px) !important;
      }
      .react-datepicker__header {
        background-color: #4dccc3;
      }
      .react-datepicker__current-month,
      .react-datepicker-time__header,
      .react-datepicker-year-header {
        color: #fff;
      }
      .react-datepicker__day-name {
        color: white !important;
        font-weight: bold;
        font-size: 15px;
      }
      .react-datepicker__day--selected,
      .react-datepicker__day--in-selecting-range,
      .react-datepicker__day--in-range,
      .react-datepicker__month-text--selected,
      .react-datepicker__month-text--in-selecting-range,
      .react-datepicker__month-text--in-range,
      .react-datepicker__quarter-text--selected,
      .react-datepicker__quarter-text--in-selecting-range,
      .react-datepicker__quarter-text--in-range,
      .react-datepicker__year-text--selected,
      .react-datepicker__year-text--in-selecting-range,
      .react-datepicker__year-text--in-range {
        background-color: unset;
        color: black;
        border: 2px solid #4dccc3;
        border-radius: 0px;
      }
      .react-datepicker__day--keyboard-selected,
      .react-datepicker__month-text--keyboard-selected,
      .react-datepicker__quarter-text--keyboard-selected,
      .react-datepicker__year-text--keyboard-selected {
        background-color: unset;
        color: black;
        border: 2px solid transparent;
        border-radius: 0px;
      }

      .react-datepicker__day-name,
      .react-datepicker__day,
      .react-datepicker__time-name {
        color: #9b9b9b;
        display: inline-block;
        width: 1.5rem !important;
        line-height: 1.5rem !important;
        text-align: center;
        margin: 0.166rem;
        font-weight: bold;
      }

      .react-datepicker__navigation--next {
        background: url("../../../assets/images/icons/nextIcon.png") no-repeat;
        width: 12px;
        height: 12px;
        border: none;
        background-size: contain;
        margin: 9px 3px;
      }

      .react-datepicker__navigation-icon--previous {
        background: url("../../../assets/images/icons/prevIcon.png") no-repeat;
        width: 12px;
        height: 12px;
        border: none;
        background-size: contain;
        margin: 9px 3px;
      }
      .react-datepicker__navigation-icon--previous::before {
        visibility: hidden;
      }
      .react-datepicker__navigation-icon--next::before {
        visibility: hidden;
      }

      // date picker css

      .date-input {
        position: absolute;
        opacity: 0;
        z-index: 3;
      }
      .disable-input {
        border: 0;
        border-bottom: 1px solid #ced4da;
        border-radius: 0;
        background-color: white;
        opacity: 1;
        cursor: pointer;
        &:focus {
          box-shadow: unset;
        }
      }
    }

    .days-input {
      border: 0;
      border-bottom: 1px solid #ced4da;
      border-radius: 0;
    }

    .submit-form {
      width: 100%;
      border: none;
      color: #fff;
      padding-top: 10px;
      padding-bottom: 10px;
      background: #fc6b6c;
    }
  }

  .progressBar {
    width: 100%;
    margin-bottom: 1rem;
    .progressInsider {
      width: 100%;
      display: flex;
      .progress {
        width: 60%;
        margin-right: 1.5rem;
      }
      .half-day {
        margin-top: -12px;
      }
    }
  }

  .client-error {
    background: none !important;
    color: red;
    padding: 6px;
    margin-bottom: 10px;
    border-radius: 3px;
    border: 1px solid #d73925;
  }

  //   input option

  fieldset.MuiOutlinedInput-notchedOutline.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    border: 0;
    border-bottom: 1px solid #ced4da;
    border-radius: 0;
  }
  div#demo-multiple-checkbox {
    padding: 12px;
  }
  .css-1xx01vn-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
    display: none !important;
  }

  .MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.MuiInputLabel-outlined.MuiFormLabel-root.MuiFormLabel-colorPrimary.MuiFormLabel-filled.css-1xx01vn-MuiFormLabel-root-MuiInputLabel-root {
    display: none !important;
  }

  //   input option
}

//   input option

li.MuiMenuItem-root.MuiMenuItem-gutters.Mui-selected.MuiButtonBase-root.inputOptionList.css-1tkwscc-MuiButtonBase-root-MuiMenuItem-root {
  background: unset;
}

.inputOptionList
  svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-i4bv87-MuiSvgIcon-root {
  color: #ffffff;
  border: 1px solid #aeaeae;
  border: 1px solid #aeaeae;
  box-shadow: inset 0 1px 3px rgb(0 0 0 / 15%);
  border-radius: 3px;
  font-size: 1.1rem;
}

li.MuiMenuItem-root.MuiMenuItem-gutters.Mui-selected.MuiButtonBase-root.inputOptionList.css-1tkwscc-MuiButtonBase-root-MuiMenuItem-root
  svg {
  font-size: 22px;
  color: #11a911;
  box-shadow: unset;
  border: 0;
}

.inputOptionList
  span.MuiTypography-root.MuiTypography-body1.MuiListItemText-primary.css-nuojon-MuiTypography-root {
  font-family: "Source Sans Pro";
  color: #54636f;
  font-weight: 500;
}

//   input option

// Update select box

.MuiFormControl-root {
  display: -webkit-inline-box !important;
  display: -webkit-inline-flex !important;
  display: -ms-inline-flexbox !important;
  display: inline-flex !important;
  -webkit-flex-direction: column !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  position: relative !important;
  min-width: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
  border: 0 !important;
  vertical-align: top !important;
  width: 100% !important;
}

.MuiInputLabel-root .MuiInputLabel-formControl {
  color: rgba(0, 0, 0, 0.6) !important;
  font-family: "Source Sans Pro", "Helvetica Neue", "Helvetica", "Arial",
    "sans-serif" !important;
  font-weight: 400 !important;
  font-size: 1rem !important;
  line-height: 1.4375em !important;
  padding: 0 !important;
  position: relative !important;
  display: block !important;
  transform-origin: top left !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  max-width: calc(133% - 24px) !important;
  position: absolute !important;
  left: 0 !important;
  top: 0 !important;
  -webkit-transform: translate(14px, -9px) scale(0.75) !important;
  -moz-transform: translate(14px, -9px) scale(0.75) !important;
  -ms-transform: translate(14px, -9px) scale(0.75) !important;
  transform: translate(14px, -9px) scale(0.75) !important;
  -webkit-transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    -webkit-transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms !important;
  transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms !important;
  z-index: 1 !important;
  pointer-events: none !important;
}

.MuiOutlinedInput-root
  .MuiInputBase-root
  .MuiInputBase-colorPrimary
  .MuiInputBase-formControl {
  font-family: "Source Sans Pro", "Helvetica Neue", "Helvetica", "Arial",
    "sans-serif" !important;
  font-weight: 400 !important;
  font-size: 1rem !important;
  line-height: 1.4375em !important;
  color: rgba(0, 0, 0, 0.87) !important;
  box-sizing: border-box !important;
  position: relative !important;
  cursor: text !important;
  display: -webkit-inline-box !important;
  display: -webkit-inline-flex !important;
  display: -ms-inline-flexbox !important;
  display: inline-flex !important;
  -webkit-align-items: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  position: relative !important;
  border-radius: 4px !important;
}
.MuiSelect-select .MuiSelect-outlined .MuiSelect-multiple {
  height: auto !important;
  min-height: 1.4375em !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  -webkit-appearance: none !important;
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
  border-radius: 4px !important;
  cursor: pointer !important;
  font: inherit !important;
  letter-spacing: inherit !important;
  color: currentColor !important;
  padding: 4px 0 5px !important;
  border: 0 !important;
  box-sizing: content-box !important;
  background: none !important;
  height: 1.4375em !important;
  margin: 0 !important;
  -webkit-tap-highlight-color: transparent !important;
  display: block !important;
  min-width: 0 !important;
  width: 100% !important;
  -webkit-animation-name: mui-auto-fill-cancel !important;
  animation-name: mui-auto-fill-cancel !important;
  -webkit-animation-duration: 10ms !important;
  animation-duration: 10ms !important;
  padding: 16.5px 14px !important;
}
.MuiSelect-nativeInput {
  bottom: 0 !important;
  left: 0 !important;
  position: absolute !important;
  opacity: 0 !important;
  pointer-events: none !important;
  width: 100% !important;
  box-sizing: border-box !important;
}
.Update-Project .MuiOutlinedInput-notchedOutline {
  border: 0 !important;
  border-bottom: 1px solid #ced4da !important;
  border-radius: 0 !important;
  text-align: left !important;
  position: absolute !important;
  bottom: 0 !important;
  right: 0 !important;
  top: -5px !important;
  left: 0 !important;
  margin: 0 !important;
  padding: 0 8px !important;
  pointer-events: none !important;
}
.Update-Project .form-width label {
  display: none !important;
  margin-bottom: 5px;
  font-weight: 700;
}

.Update-Project h2#customized-dialog-title {
  color: #fc6b6c !important;
  font-size: 16px !important;
  text-align: center !important;
  padding: 7px !important;
}
.Update-Project h2#customized-dialog-title svg {
  border: 1px solid #9e9e9e;
  border-radius: 50px;
  margin-top: -8px !important;
}
