/* *{
    background-color: aqua;
    border: 2px solid red;
} */


.forgotPage {
  height: 100vh;
  background-color: white;
  padding: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  /* border: 4px solid red; */
  /* background-image: url("../../assets/BG/previewBG.png"); */
  margin: -15px;
}
.SmallDiagloge{
  font-size: 16px;
  font-weight: 400;
  font-family: 'Poppins', sans-serif;
  /* border: 1px solid red; */
  margin-top: -50px;
}
.leftDiv{
  width: 65%;
  /* border: 2px solid black; */
  height: 100vh;
  display: flex;
  gap: 60px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* margin-top: -50px; */
}
.errorDivForgot{
  margin-top: -50px;
  margin-bottom: -30px;
  /* border: 1px solid red; */
}
.errorDivForgot p{
  font-size: 16px;
  color:red;
  font-family: 'Poppins', sans-serif;
  font-weight: 300;
  height: 20px;
  min-height: 20px;
}
.errorDivForgot h6{
  color: white;
  margin: 12px 0;
}

.loginOption{
  font-size: 22px;
  font-family: 'Poppins', sans-serif;
  font-weight: 300;
}
.loginTag{
  font-size: 22px;
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  color: #004aad;
}

.rightDiv1{
  width: 35%;
  /* border: 2px solid black; */
  /* background-color: #19acb4; */
  background-image: url("../../assets/BG/bg.png");
  background-size: 100%;
  height: 100vh;
  display: flex;
  gap: 60px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.craeteHeader{
  font-size: 48px;
  font-weight: 600;
  color: #19ACB4;
  font-family: 'Poppins', sans-serif;
  margin-top: -30px;
}
.inputDiv{
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.InputBox{
  /* background-color: red; */
  display: flex;
  align-items: center;
  /* border: 2px solid red; */
  outline: none;
  border-radius: 40px;
  background-color: #d9d9d9;
  padding: 7px;
  width: 320px;
  padding-left: 20px;
  font-size: larger;
  color: black;
  font-family: 'Poppins', sans-serif;
  /* border: 2px solid rebeccapurple; */
}
.InputBox2{
  /* background-color: red; */
  border: none;
  outline: none;
  border-radius: 40px;
  background-color: #d9d9d9;
  width: 100%;
  /* padding: 7px; */
  /* width: 380px; */
  padding-left: 30px;
  font-size: larger;
  color: black;
  color-scheme: black;
  
  /* border: 2px solid rebeccapurple; */
}
.InputBox2::placeholder{
  color: black;
  font-size: 400;
  font-weight: 300;
}
.Cirlce{
  border: 1px solid black;
  display: flex;
  justify-content: center;
  padding: 2px;
  border-radius: 50%;
}

.forgotDiv{
  /* border: 1px solid red; */
  width: 320px;
  display: flex;
  font-family: 'Poppins', sans-serif;
  color: #004aad;
  font-weight: 300;
  font-size: medium;
  margin: -35px 0;
  justify-content: flex-end;
}
.forgotAtag{
  width: 320px;
  display: flex;
  font-family: 'Poppins', sans-serif;
  color: #004aad;
  font-weight: 300;
  font-size: medium;
  text-decoration: none;
  /* margin: -35px 0; */
  cursor: pointer;
  justify-content: flex-end;

}

.welcomeHeading{
  color: white;
  font-weight: 600;
  font-size: 36px;
  font-family: 'Poppins', sans-serif;
  /* font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif ; */
  

  /* border: 1px solid red; */
  /* width: 400px; */
  font-stretch: ultra-expanded;
}
.dialogeHeading{
  /* max-width: 50px; */
  width: 280px;
  text-align: center;
  color: white;
  font-size: 22px;
  font-weight: 300;
  margin-top: 20px;
  font-family: 'Poppins', sans-serif;
  /* border: 1px solid red; */
}
.signUpButton{
  color: white;
  font-size: 23.5px;
  font-weight: 300;
  text-align: center;
  padding: 5px;
  width: 270px;
  border-radius: 40px;
  background-color: #40c1c8;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  /* border: 3px solid white; */
  font-family: 'Poppins', sans-serif;
  /* opacity: 0.6; */
  /* transition: 0.3s; */
  box-shadow: 0 3px #999;
}
.signUpButton:hover{
  animation: ease-in;
  background-color: #19ACB4
}
.signUpButton:active {
  background-color: #19ACB4;
  box-shadow: 0 3px #666;
  transform: translateY(4px);
}
.signinButton{
  color: white;
  font-size: 23.5px;
  font-weight: 300;
  text-align: center;
  padding: 5px;
  width: 170px;
  border-radius: 40px;
  cursor: pointer;
  border: 3px solid white;
  font-family: 'Poppins', sans-serif;
  /* opacity: 0.6; */
  transition: 0.3s;
  box-shadow: 0 3px #40c1c8;
}
.signinButton:active {
  background-color: #19ACB4;
  box-shadow: 0 5px #40c1c8;
  transform: translateY(4px);
}
.signinButton:hover{
  /* background-color: #19acb45e; */
  animation: ease-in;
  background-color: #1d777cb6;
}

.centerDiv{
  display: flex;
  flex-direction: column;
  gap: 50px;
  align-items: center;
  margin-top: -40px;
  /* border: 1px solid red; */
}
.aTag{
  color: white;
  text-decoration: none;
}
.aTag:hover{
  color: white;
}


.signup {
    height: 100vh;
    background-color: #54636f;
    padding: 0 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background-image: url("../../assets/BG/previewBG.png"); */
    margin: -15px;
  }
  .pre-head {
    font-weight: 700;
  }
  .login__form {
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
    /* border: 2px solid red; */
  }
  .form-input {
    border: none;
    margin-bottom: 25px;
    outline: 0 !important;
    background: transparent !important;
    border-bottom: 1px solid #abb2b8 !important;
    padding: 10px 20px !important;
    font-size: 24px !important;
    box-shadow: none !important;
    color: #abb2b8 !important;
    border-radius: 5px !important;
  }
  .login__button {
    border: none;
    background: transparent;
    color: #abb2b8;
    font-size: 24px !important;
    padding: 10px 16px;
    cursor: pointer;
    line-height: 1.33;
    border-radius: 6px;
    border: 2px solid transparent;
  }
  .login__button:focus {
    background: #428bca;
    color: white;
    border: 2px solid black;
  }
  .login__button:hover {
    color: white;
    background: transparent;
  }
  .alert {
    padding: 15px;
    border: 1px solid transparent;
    border-radius: 4px;
  }
  .alert-danger {
    background: f2dede !important;
    color: red !important;
    border: 1px solid;
  }
  .form-signin-heading {
    color: #fff;
    text-align: center;
  }
  .login__headingHead {
    color: #fff;
    font-weight: 550;
  }
  .already-user{
    display: flex;
    /* align-items: center; */
    justify-content: center;
    color: #abb2b8;
    margin-top: 10px;
  }
  .a-tag{
    text-decoration: none;
    color: white;
  }
  .a-tag:hover{
    color: #abb2b8;
  }

  .heading{
    /* margin-bottom: 2px; */
    position: relative;
    bottom: 60px;
    color: #fff;
  }
  /* Media Queries */

@media (max-width: 860px) {
  .leftDiv {
    width: 100%;
    margin-top: 0;
  }

  .craeteHeader {
    font-size: 40px;
  }
  
  .rightDiv1 {
    width: 60%;
  }
  
  /* .InputBox,
  .InputBox2 {
    width: 280px;
  } */
  
  .welcomeHeading {
    font-size: 33px;
  }
  
  .dialogeHeading {
    width: 250px;
  }
  
  .signUpButton {
    width: 270px;
    font-size: 20px;
  }
}
@media (max-width : 740px){
  .craeteHeader {
    font-size: 30px;
  }
  .welcomeHeading {
    font-size: 30px;
  }
}

@media (max-width: 600px) {
  .forgotPage {
    padding: 0;
  }
  
  .rightDiv1{
    display: none;
  }
  .leftDiv {
    width: 100%;
  }
  
  .loginTag,
  .loginOption {
    font-size: 18px;
  }
  
  .craeteHeader {
    font-size: 36px;
  }
  
  /* .InputBox,
  .InputBox2 {
    width: 250px;
  } */
  
  .signUpButton {
    width: 250px;
    font-size: 20px;
  }
  
  .welcomeHeading {
    font-size: 30px;
  }
  
  .dialogeHeading {
    width: 200px;
  }
}
@media (max-width: 600px) {
  .craeteHeader {
    font-size: 28px;
  }
}