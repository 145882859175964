.add-client {
  .box-header {
    padding: 31px 0 0 0;
    margin-bottom: 20px;
    .add-client {
      font-size: 20px;
      font-weight: 600;
      color: #fc6b6c;
      letter-spacing: 0.5px;
      cursor: pointer;
      white-space: nowrap;
    }
  }
}

.main-header {
  border-bottom: 1px solid #bebebe;
  align-items: inherit;
  .add-client-title {
    color: #fc6b6c;
    font-size: 16px;
    text-align: center;
  }
}

.add-client-middle {
  .inputBorder {
    border: 0;
    border-bottom: 1px solid #bebebe;
    border-radius: 0;
    &:focus {
      box-shadow: unset;
      border-bottom: 1px solid #bebebe;
    }
    &::placeholder {
      font-family: "Source Sans Pro";
    }
  }
  .submit {
    color: white;
    border-radius: 0;
    background: #fc6b6c;
    margin-top: 1rem;
  }

  // .client-error {
    //   background: none !important;
    //   color: red;
    //   padding: 6px;
    //   margin-bottom: 10px;
    //   border-radius: 3px;
    //   border: 1px solid #d73925;
    // }
  }
  
  #client-error {
    background: none !important;
    color: red;
    display: block;
    padding: 1px;
    // width: 100%;
    margin-bottom: 10px;
    border-radius: 3px;
    border: 1px solid #d73925;
  }