:root {
  --themeColor: ;
  --themeColorSecondary: ;
}

.holiday__heading {
  margin-top: 2.3rem;
  font-size: 24px;
  margin-left: 0.2rem;
  margin-bottom: 2rem;
  font-weight: 600;
  color: #fc6b6c;
  letter-spacing: 0.5px;
  font-family: "Source Sans Pro", "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.react-calendar {
  margin-left: auto;
  margin-right: auto;
  width: 100% !important;
  max-width: 100%;
  background: white;
  border: 1px solid #a0a096;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
  box-shadow: 2px 2px 9px 3px #8888;
  border: none !important;

  .react-calendar__year-view__months {
    max-width: 80%;
    margin: auto;
  }

  button.react-calendar__tile.react-calendar__year-view__months__month.tile__color {
    padding: 0 !important;
    height: 80px !important;
    min-height: 55px !important;
    max-height: 34px !important;
    flex-basis: 286px !important;
    margin: auto;
    margin-bottom: 4rem;
    border-radius: 5px !important;
  }
  button.react-calendar__tile.react-calendar__decade-view__years__year.tile__color {
    padding: 0 !important;
    height: 80px !important;
    min-height: 55px !important;
    max-height: 34px !important;
    flex-basis: 286px !important;
    margin: auto;
    margin-bottom: 4rem;
    border-radius: 5px !important;
  }

  button.react-calendar__tile.react-calendar__century-view__decades__decade.tile__color {
    padding: 0 !important;
    height: 80px !important;
    min-height: 55px !important;
    max-height: 34px !important;
    flex-basis: 286px !important;
    margin: auto;
    margin-bottom: 4rem;
    border-radius: 5px !important;
  }
}
.react-calendar__navigation {
  display: flex;
  margin-bottom: 1em;
  background-color: var(--themeColor);
  color: white;
}
.react-calendar__navigation > button {
  color: white;
  padding: 0px 76px;
}
.tile__color {
  color: gray;
}
.react-calendar__month-view__days__day--weekend {
  color: gray !important;
}

.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75em;
  background-color: var(--themeColor);
  margin-top: -20px;
  color: white;
}
abbr[title] {
  text-decoration: none !important;
}
.react-calendar__tile {
  max-width: 100%;
  text-align: center;
  padding: 2rem 0.5rem !important;
}
.react-calendar__tile--now {
  background: #cccccc !important;
  border-radius: 5px !important;
}
.header {
  box-shadow: none !important;
}

.calender_onHoliday {
  .react-calendar__tile--active {
    background: var(--themeColor);
    color: grey;
    border: 2px solid var(--themeColor) !important;
  }

  .react-calendar__tile--active:enabled:hover,
  .react-calendar__tile--active:enabled:focus {
    background: #006edc00;
    color: grey;
    border: 2px solid var(--themeColor) !important;
  }
}

.highlight {
  background: var(--themeColorSecondary) !important;
  color: white !important;
  border-radius: 7px;
  &:hover {
    background-color: #e6e6e6 !important;
  }
  &:focus {
    background-color: #e6e6e6 !important;
  }
}
