:root {
  --themeColor: ;
  --themeColorSecondary: ;
}

.dashboard_content {
  .displayEntries {
    margin-top: 20px;
    .time-entries-main {
      margin-bottom: 2em;
      .display_date_entries {
        list-style: none;
        list-style-type: none;
        padding-left: 0;
      }
    }
    .time-entries-info {
      background-color: #fff;
      padding: 0.6em;
      font-size: 1em;
      box-shadow: inset 0 -1px 0 0 #eceded;
      color: #868282;
    }
    .input-timer-1 {
      width: 95%;
      padding: 2px 6px;
      text-overflow: ellipsis;
      min-height: 25px;
      font-size: 16px;
      @media only screen and (max-width: 766px) {
        font-size: 14px;
      }
    }
    .time-entry-button {
      border: none;
      background: none;
      img {
        width: 30px;
        @media only screen and (max-width: 766px) {
          width: 20px;
        }
      }
    }
    .rotate-45 {
      transform: rotate(45deg);
    }
  }
  .btn-img {
    opacity: 0.7;
  }
  .load-more-btn {
    border: 1px solid #ccc;
    background: #fff;
    border-radius: 50px;
    box-shadow: 0px 1px 6px 0px #ccc;
    padding: 10px 15px;
  }
  .load-more-btn:focus {
    border: none;
    outline: none;
  }
  [contenteditable]:focus {
    outline: 1px solid lightgray;
    border-radius: 5px;
    padding: 2px 10px;
  }
  iframe {
    display: none !important;
    top: 0px !important;
    left: 0px !important;
    width: 100% !important;
    height: 100% !important;
    border: none !important;
    z-index: 0 !important;
  }

  select {
    display: block;
    width: 100%;
    padding: 2px 10px;
    -moz-padding-start: calc(0.75rem - 3px);
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    border-radius: 0.25rem;
    border: 1px solid lightgray !important;
    color: #212529;
    background-color: #fff;
    border-radius: 5px;
  }
  .load-more-btn {
    color: #444;
    font-size: 14px;
    line-height: 1.428571429;
  }
  .load-more-btn:hover {
    border: 1px solid rgb(168, 168, 168);
  }
  .date-info {
    margin-bottom: 1em;
    font-size: 1.25em;
    color: #525252;
    .date-info-clockIcon {
      font-size: 20px !important;
      margin-top: -5px;
      margin-right: 3px;
      color: #bcc0c7;
      margin-left: -5px;
    }
    @media only screen and (max-width: 766px) {
      margin-bottom: 0.5em;
      font-size: 1.1225em;
    }
  }
  .date-duration-separator {
    margin-left: 15px;
    margin-right: 15px;
    font-size: 1.3em;
  }
  .fa-refresh:before {
    content: "\f021";
    font-size: small;
  }
}
.workspacModel{
  border: 1px solid rgb(210, 207, 207);
  display: flex;
  justify-content: center;
  padding: 10px 0;
  margin-bottom: 5px;
  cursor: pointer;
  // background-color: rgba(162, 194, 231, 0.737);
}

// /* Timer Model */
.updateTime {
  // height: 190px;
  width: 275px;
  position: absolute;
  left: 46%;
  z-index: 1000;
  background: #fff;
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  box-shadow: 0 2px 6px 0 rgb(0 0 0 / 20%);
  text-align: center;
  @media only screen and (max-width: 425px) {
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }

  .updateDiv {
    color: #54636f;
    max-width: 100%;
    background-color: transparent;
    border-collapse: collapse;
    border-spacing: 0;

    tr {
      display: table-row;
      vertical-align: inherit;
      border-color: inherit;
      th {
        text-align: left !important;
        text-transform: uppercase;
        font-size: 12px !important;
        font-weight: 600;
      }
      td {
        &:first-child {
          padding: 0 5px 0 0;
        }
        .input-timer {
          width: 100%;
          color: #333;
          background-color: #fff;
          height: auto;
          border-radius: 5px;
          outline: 0;
          border: 1px solid #ccc;
          padding: 2px 6px;
          text-transform: uppercase;
        }
        .save-time-btn {
          font-size: 12px;
          width: 100%;
          font-weight: 600;
          border: 2px solid var(--themeColorSecondary);
          text-transform: uppercase;
          border-radius: 5px;
          color: var(--themeColorSecondary);
          padding: 5px 6px;
          background-color: white;
        }
        .input-group-addon {
          display: inline-flex;
          border-radius: 5px;
          border: 1px solid rgba(0, 0, 0, 0.2);
          padding: 6px 12px;
          height: 30px;
        }
      }
    }
  }
}
// /* Timer modal end */

// /* Date Picker Css */
.react-datepicker-wrapper {
  width: 84% !important;
  float: left;
  .react-datepicker__input-container {
    width: 100%;
  }
}
.react-datepicker__input-container > input {
  width: 100%;
  color: #333;
  background-color: #fff;
  height: auto;
  border-radius: 5px;
  outline: 0;
  border: 1px solid #ccc;
  padding: 2px 6px;
  float: left;
}

.react-datepicker-popper[data-placement^="top"]
  .react-datepicker__triangle::after {
  bottom: 1px !important;
}

.react-datepicker-popper[data-placement^="top"]
  .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^="top"]
  .react-datepicker__triangle::after,
.react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::after {
  left: -17px;
}
.react-datepicker__triangle {
  transform: translate(30px, 0px) !important;
}
.react-datepicker__header {
  background-color: var(--themeColor) !important;
}
.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  color: #fff !important;
}
.react-datepicker__day-name {
  color: white !important;
  font-weight: bold !important;
  font-size: 15px !important;
}
.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  background-color: unset !important;
  color: black !important;
  border: 2px solid var(--themeColor) !important;
  border-radius: 0px !important;
}
.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  background-color: unset;
  color: black;
  border: 2px solid transparent;
  border-radius: 0px;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  color: #9b9b9b;
  display: inline-block;
  width: 1.5rem !important;
  line-height: 1.5rem !important;
  text-align: center;
  margin: 0.166rem;
  font-weight: bold;
}

.react-datepicker__navigation--next {
  background: url("../../assets/images/icons/nextIcon.png") no-repeat !important;
  width: 12px !important;
  height: 12px !important;
  border: none !important;
  background-size: contain !important;
  margin: 9px 3px !important;
}

.react-datepicker__navigation-icon--previous {
  background: url("../../assets/images/icons/prevIcon.png") no-repeat !important;
  width: 12px !important;
  height: 12px !important;
  border: none !important;
  background-size: contain !important;
  margin: 9px 3px !important;
}
.react-datepicker__navigation-icon--previous::before {
  visibility: hidden;
}
.react-datepicker__navigation-icon--next::before {
  visibility: hidden;
}

.scrollUp {
  display: none;
  position: fixed;
  bottom: 1px;
  right: 5px;
  z-index: 99;
  font-size: 35px;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 20px 23px;
  border-radius: 4px;
  &:hover {
    cursor: pointer;
  }
}
.fa-angle-double-up:before {
  content: "\f102";
}
.fa-2 {
  font-weight: 400;
  cursor: pointer;
}
.fa-3 {
  font-weight: 900;
  cursor: pointer;
}
.dashboard__loadmoreIcon {
  font-size: 1.3rem !important;
  transform: rotate(130deg);
}
.dashboard__scrollIconFirst {
  font-size: 2.7rem !important;
  font-weight: 700 !important;
}
.dashboard__scrollIconSecond {
  font-size: 2.7rem !important;
  font-weight: 700 !important;
  margin-top: -30px;
}
.dashboard__modal {
  background-color: white;
  width: fit-content;
  min-width: 15rem;
  padding: 10px 20px;
  box-shadow: 0px 11px 15px -7px rgb(0 0 0 / 20%),
    0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%);
  border-radius: 10px;
  z-index: 5;
  position: absolute;
  bottom: 1px;
  right: 5px;
}
.dashboard__SettingsIconContainer {
  position: fixed;
  bottom: 40px;
  right: 70px;
}
.dashboard__SettingsIcon {
  width: 2.8rem;
  animation: rotation 6s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.dashboard__modal__container {
  display: flex;
  align-items: center;
  border-bottom: 2px solid whitesmoke;
}
.dashboard__modal__input {
  margin-left: 15px;
  border: none;
  width: 5.5rem;
  border-radius: 5px;
}
.dashboard__modal__text {
  margin-left: 15px;
  margin-top: 4px;
}
.dashboard__modalHeading {
  font-size: 1.3rem;
  font-weight: 600;
  color: gray;
}

input[type="radio"]:checked {
  color: pink;
  background-color: pink;
}
.dashboard__modalCloseIcon {
  font-size: 1.8rem !important;
  color: gray;
  cursor: pointer;
}

.radio {
  display: inline-flex;
}

.radio__input {
  display: none;
}

.radio__radio {
  width: 1.25em;
  height: 1.25em;
  border: 2px solid #c0c0c0;
  border-radius: 50%;
  box-sizing: border-box;
  padding: 3px;
  transition: border 0.15s;
  cursor: pointer;
}

.radio__radio::after {
  content: "";
  width: 100%;
  height: 100%;
  display: block;
  background: #c94e2c;
  border-radius: 50%;

  transform: scale(0);
  transition: transform 0.15s;
}

.radio__input:checked + .radio__radio::after {
  transform: scale(1);
}

.radio__input:checked + .radio__radio {
  border: 2px solid #c94e2c;
}
.time-entry-button:focus {
  border: none;
  outline: none;
}

.enableTimerEditContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.enableTimerEditContainer > p {
  margin-bottom: 0;
  padding: 12px 0px;
}
