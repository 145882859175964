.ThemedButton {
  width: 285px !important;
  font-size: 17px !important;
  text-transform: capitalize !important;
  font-weight: 500 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: flex-start !important;
  padding: 12px 18px !important;
  border-top-left-radius: 9px !important;
  border-bottom-left-radius: 0px !important;
  border-top-right-radius: 9px !important;
  border-bottom-right-radius: 0px !important;
  position: fixed !important;
  bottom: 0 !important;
  border: none !important;
}
.ThemedButton > img {
  width: 28px !important;
  filter: brightness(0) invert(1) !important;
  margin: 0 !important;
}
.ThemedButton > span {
  margin-left: 38px !important;
  font-size: 18px !important;
}
// .ThemedButton:hover {
//   background-color: #fc6b6c !important;
//   color: gold !important;
// }
@media (max-width: 766px) {
  .ThemedButton > span {
    display: none !important;
  }
}
