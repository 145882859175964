.Project-type-content {
  .box-header {
    // padding: 31px 0 0 0;
    margin-bottom: 20px;
    .add-client {
      font-size: 20px;
      font-weight: 600;
      color: #fc6b6c;
      letter-spacing: 0.5px;
      cursor: pointer;
      white-space: nowrap;
    }
  }
}
.add-Project-leave-type {
  h2#customized-dialog-title {
    color: #fc6b6c;
    font-size: 16px;
    text-align: center;
    svg {
      border: 1px solid #9e9e9e;
      border-radius: 50px;
    }
  }
  .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
    padding: 1rem;
  }
  .css-1t4vnk2-MuiDialogContent-root {
    border-bottom: 0;
  }
  .form-width {
    min-width: 500px;
    label {
      display: inline-block;
      margin-bottom: 5px;
      font-weight: bold;
    }

    select {
      border: 0;
      border-bottom: 1px solid #bebebe;
      border-radius: 0;
      &:focus {
        box-shadow: unset;
      }
    }

    .flex-input {
      position: relative;
      display: flex;
      .date-icon {
        position: absolute;
        right: 13px;
        top: 7px;
      }

      // date picker css
      .react-datepicker-wrapper {
        position: absolute;
        opacity: 0;
        z-index: 3;
      }
      .react-datepicker-popper[data-placement^="top"]
        .react-datepicker__triangle::after {
        bottom: 1px;
      }

      .react-datepicker-popper[data-placement^="top"]
        .react-datepicker__triangle::before,
      .react-datepicker-popper[data-placement^="bottom"]
        .react-datepicker__triangle::before,
      .react-datepicker-popper[data-placement^="top"]
        .react-datepicker__triangle::after,
      .react-datepicker-popper[data-placement^="bottom"]
        .react-datepicker__triangle::after {
        left: -17px;
      }
      .react-datepicker__triangle {
        transform: translate(30px, 0px) !important;
      }
      .react-datepicker__header {
        background-color: #4dccc3;
      }
      .react-datepicker__current-month,
      .react-datepicker-time__header,
      .react-datepicker-year-header {
        color: #fff;
      }
      .react-datepicker__day-name {
        color: white !important;
        font-weight: bold;
        font-size: 15px;
      }
      .react-datepicker__day--selected,
      .react-datepicker__day--in-selecting-range,
      .react-datepicker__day--in-range,
      .react-datepicker__month-text--selected,
      .react-datepicker__month-text--in-selecting-range,
      .react-datepicker__month-text--in-range,
      .react-datepicker__quarter-text--selected,
      .react-datepicker__quarter-text--in-selecting-range,
      .react-datepicker__quarter-text--in-range,
      .react-datepicker__year-text--selected,
      .react-datepicker__year-text--in-selecting-range,
      .react-datepicker__year-text--in-range {
        background-color: unset;
        color: black;
        border: 2px solid #4dccc3;
        border-radius: 0px;
      }
      .react-datepicker__day--keyboard-selected,
      .react-datepicker__month-text--keyboard-selected,
      .react-datepicker__quarter-text--keyboard-selected,
      .react-datepicker__year-text--keyboard-selected {
        background-color: unset;
        color: black;
        border: 2px solid transparent;
        border-radius: 0px;
      }

      .react-datepicker__day-name,
      .react-datepicker__day,
      .react-datepicker__time-name {
        color: #9b9b9b;
        display: inline-block;
        width: 1.5rem !important;
        line-height: 1.5rem !important;
        text-align: center;
        margin: 0.166rem;
        font-weight: bold;
      }

      .react-datepicker__navigation--next {
        background: url("../../../assets/images/icons/nextIcon.png") no-repeat;
        width: 12px;
        height: 12px;
        border: none;
        background-size: contain;
        margin: 9px 3px;
      }

      .react-datepicker__navigation-icon--previous {
        background: url("../../../assets/images/icons/prevIcon.png") no-repeat;
        width: 12px;
        height: 12px;
        border: none;
        background-size: contain;
        margin: 9px 3px;
      }
      .react-datepicker__navigation-icon--previous::before {
        visibility: hidden;
      }
      .react-datepicker__navigation-icon--next::before {
        visibility: hidden;
      }

      // date picker css

      .date-input {
        position: absolute;
        opacity: 0;
        z-index: 3;
      }
      .disable-input {
        border: 0;
        border-bottom: 1px solid #ced4da;
        border-radius: 0;
        background-color: white;
        opacity: 1;
        cursor: pointer;
        &:focus {
          box-shadow: unset;
        }
      }
    }

    .days-input {
      border: 0;
      border-bottom: 1px solid #ced4da;
      border-radius: 0;
    }

    .submit-form {
      width: 100%;
      border: none;
      color: #fff;
      padding-top: 10px;
      padding-bottom: 10px;
      background: #fc6b6c;
    }
  }

  .progressBar {
    width: 100%;
    margin-bottom: 1rem;
    .progressInsider {
      width: 100%;
      display: flex;
      .progress {
        width: 60%;
        margin-right: 1.5rem;
      }
      .half-day {
        margin-top: -12px;
      }
    }
  }

  .client-error {
    background: none !important;
    color: red;
    padding: 6px;
    margin-bottom: 10px;
    border-radius: 3px;
    border: 1px solid #d73925;
  }

  //   input option

  fieldset.MuiOutlinedInput-notchedOutline.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    border: 0;
    border-bottom: 1px solid #ced4da;
    border-radius: 0;
  }
  div#demo-multiple-checkbox {
    padding: 12px;
  }
  .css-1xx01vn-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
    display: none;
  }

  .MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.MuiInputLabel-outlined.MuiFormLabel-root.MuiFormLabel-colorPrimary.MuiFormLabel-filled.css-1xx01vn-MuiFormLabel-root-MuiInputLabel-root {
    display: none !important;
  }

  //   input option
}

//   input option

li.MuiMenuItem-root.MuiMenuItem-gutters.Mui-selected.MuiButtonBase-root.inputOptionList.css-1tkwscc-MuiButtonBase-root-MuiMenuItem-root {
  background: unset;
}

.inputOptionList
  svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-i4bv87-MuiSvgIcon-root {
  color: #ffffff;
  border: 1px solid #aeaeae;
  border: 1px solid #aeaeae;
  box-shadow: inset 0 1px 3px rgb(0 0 0 / 15%);
  border-radius: 3px;
  font-size: 1.1rem;
}

li.MuiMenuItem-root.MuiMenuItem-gutters.Mui-selected.MuiButtonBase-root.inputOptionList.css-1tkwscc-MuiButtonBase-root-MuiMenuItem-root
  svg {
  font-size: 22px;
  color: #11a911;
  box-shadow: unset;
  border: 0;
}

.inputOptionList
  span.MuiTypography-root.MuiTypography-body1.MuiListItemText-primary.css-nuojon-MuiTypography-root {
  font-family: "Source Sans Pro";
  color: #54636f;
  font-weight: 500;
}

//   input option
