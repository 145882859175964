* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0 !important;
  overflow: auto !important;
  font-family: "Source Sans Pro", sans-serif !important;
}

iframe {
  display: none !important;
  top: 0px !important;
  left: 0px !important;
  width: 100% !important;
  height: 100% !important;
  border: none !important;
  z-index: 0 !important;
}
