.leave-report-filter {
  width: 50%;
  .flex-inputs {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .inner-Input {
      border-bottom: 1px solid #e5e5e5;
      display: flex;
      align-items: center;
      position: relative;
      width: 100%;
      margin-right: 1rem;
      i {
        position: absolute;
        right: 20px;
      }

      input {
        border: unset;
        &:focus {
          box-shadow: 0px 0px 0px 1px #d3d3d3;
        }
      }
    }
  }

  .go-button {
    button {
      font-size: 20px;
      font-weight: 600;
      color: #fc6b6c;
      letter-spacing: 0.5px;
      &:focus {
        box-shadow: unset;
      }
    }
  }
}
