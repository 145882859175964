:root {
  --themeColorStartDate: ;
}

.calendar-table > .table-condensed > thead {
  background-color: var(--themeColorStartDate) !important;
  color: white !important;
}
.daterangepicker .calendar-table th,
.daterangepicker .calendar-table td {
  border-radius: 0 !important;
  border: 0 !important;
}
.daterangepicker .calendar-table .next span,
.daterangepicker .calendar-table .prev span {
  color: #fff !important;
  border: solid #fff !important;
  border-width: 0 2px 2px 0 !important;
  border-radius: 0 !important;
  display: inline-block !important;
  padding: 3px !important;
}
.daterangepicker td.active,
.daterangepicker td.active:hover {
  background-color: white !important;
  border: 2px solid var(--themeColorStartDate) !important;
  color: inherit !important;
}
.daterangepicker > .ranges {
  background-color: var(--themeColorStartDate) !important;
  color: #fff !important;
}
.daterangepicker > .ranges:hover {
  background-color: var(--themeColorStartDate) !important;
  color: #fff !important;
}
.daterangepicker .calendar-table th,
.daterangepicker .calendar-table td {
  height: 30px !important;
}
.datapicker__button {
  font-size: 13px !important;
  height: 5px !important;
}

.daterangepicker .ranges li {
  font-size: 13.5px !important;
  padding: 8px 12px !important;
  cursor: pointer !important;
  font-weight: bold !important;
}

.daterangepicker .ranges li.active {
  background-color: var(--themeColorStartDate) !important;
  color: #fff !important;
}

.daterangepicker .ranges li:hover {
  background-color: var(--themeColorStartDate) !important;
  color: #fff !important;
}
.react-bootstrap-daterangepicker-container {
  padding-top: 10px !important;
  padding-bottom: 5px;
}
.datapicker__buttonData {
  font-size: 12px;
}
.daterangepicker.opensright:before {
  left: 318px !important;
}
.daterangepicker.opensright:after {
  left: 318px !important;
}
.daterangepicker {
  right: 194px !important;
}
